import React from "react";
import Layout from "../components/layout";
import "../styles/app.scss";
import imgSrc from "../assets/images/packaging-webinars-hero.jpg";
export default function Home() {
  return (
    <>
      <Layout
        title="Packaging your case for a successful journey webinars | HSBC for Intermediaries"
        metaDescription="HSBC UK‘s ‘Packaging your case for a successful journey’ webinar"
        disableWrapper={true}
      >
        <div></div>
        <div
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundPosition: "center 20%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            height: "33vw", 
            width: "100%", 
            maxHeight: "450px",
          }}
        ></div>
        <div
          style={{
            padding: "25px 0",
          }}
        >
          <div className="wrapper" style={{ display: "block" }}>
            <h1 className="page-title">HSBC UK‘s ‘Packaging your case for a successful journey’ webinar  </h1>
            <p>
            To register for one of the webinars, please click on your preferred option below and enter your details.
            </p>
          </div>
          <div className="wrapper">
            <div className="accordion__body"> 
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_-yKuRUeCTYmmlQM6H6K3Ng">
                        Monday 9<sup>th</sup> December - 10:00
                        </a>
                      </p>
                    </td>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_xFEkMa9PTiSOOHaQN5FErg">
                        Tuesday 10<sup>th</sup> December - 14:00
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <a href="https://hsbc.zoom.us/webinar/register/WN_u1BEa6ptQ4WJu6UsDR6oMA">
                        Wednesday 11<sup>th</sup> December - 10:00
                        </a>
                      </p>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
